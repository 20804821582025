.header {
  padding: 30px;
  font-size: 40px;
  text-align: center;
  background: white;
}

.feed {
  display: grid;
  place-items: center;
}

.App {
  background: rgb(207, 225, 241);
}

.card {
  background-color: white;
  padding: 20px;
  margin-top: 20px;
  width: 60%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
}

small {
  color: rgb(100, 100, 100);
}

footer {
  height: 70px;
  bottom: 0;
  padding: 20px;
  width: 100%;
  background-color: white;
}